.shopping {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    color: #fff;
    padding: 0 20px;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .topFirst {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                font-size: 60px;
                color: #fff;
            }

            .titleContain {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .mainTitle {
                    font-size: 28px;
                    font-weight: 500;
                }
                .moreTitle {
                    font-size: 18px;
                    font-weight: lighter;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            padding: 0 20px;

            .rightContent {
                display: flex;
                align-items: center;
                overflow: hidden;

                .buyBtn {
                    width: 100px;
                    height: 40px;
                    background-color: #13cc68;
                    overflow: hidden;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 20px;
                    cursor: pointer;
                    border: none;

                    &:hover {
                        background-color: #0d7c41;
                    }
                    &:active {
                        background-color: #084e29;
                    }
                    &:disabled {
                        cursor: not-allowed;
                    }
                }

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
                .notifContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 0.5;
                    padding: 10px;

                    .combine {
                        position: relative;
                        cursor: pointer;

                        .icon {
                            padding: 5px;
                            background-color: #393a3f;
                            border-radius: 10px;
                            margin-right: 10px;
                            font-size: 20px !important;
                            color: #fff;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 3px;
                            right: 15px;
                            border-radius: 50%;
                            background-color: #13cc68;
                        }
                    }
                }
            }
        }

        .leftMobile {
            display: none;
        }
        .middleMobile {
            display: none;
        }
        .rightMobile {
            display: none;
        }
    }
    @media (max-width: 915px) {
        .topFirst {
            padding: 20px 0;

            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .leftMobile {
                flex: 0.2;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 0 10px;

                .menuBtn {
                    cursor: pointer;
                }
            }
            .middleMobile {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
            .rightMobile {
                flex: 0.2;
                display: flex;
                padding: 0 10px;

                .profileContain {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
            }
        }
    }

    .shoppingContain {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;

        .productContain {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-right: 15px;

            .headerContain {
                display: flex;
                align-items: center;
                padding-bottom: 10px;
                white-space: nowrap;

                .product {
                    flex: 1;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                    margin-right: 5px;
                }
                .quantity {
                    flex: 0.3;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                    margin-right: 5px;
                }
                .price {
                    flex: 0.5;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                    margin-right: 5px;
                }
                .delete {
                    flex: 0.1;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                }
            }

            .seperator {
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.28);
            }

            .shoppingFooter {
                display: flex;
                flex-wrap: wrap-reverse;
                padding-bottom: 10px;

                .footerLeft {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;

                    .shoppingBtn {
                        display: flex;
                        align-items: center;
                        height: 35px;
                        background-color: transparent;
                        padding: 5px 15px;
                        border: 1px solid #fff;
                        border-radius: 20px;
                        color: #fff;
                        font-size: 16px;
                        overflow: hidden;
                        cursor: pointer;
                        white-space: nowrap;

                        .iconBtn {
                            margin-right: 5px;
                        }
                    }
                }

                .footerRight {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding-top: 30px;

                    .textContain {
                        display: flex;

                        .txt {
                            font-size: 17px;
                            margin: 10px 0;
                            margin-left: 5px;
                            text-align: right;
                            white-space: nowrap;
                        }

                        .txtLeft {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                        }
                        .txtRight {
                            flex: 0.3;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                        }
                    }
                }
            }
        }

        .paymentContain {
            flex: 0.3;
            display: flex;
            flex-direction: column;
            min-height: 60vh;
            padding: 15px;
            border-radius: 10px;
            background-image: linear-gradient(
                to left bottom,
                rgba(23, 28, 29, 0.6),
                #171c1d,
                #171c1d,
                #181b1d,
                rgba(24, 27, 29, 1)
            );
            white-space: nowrap;

            .payInfo {
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 23px;
            }

            .payMethod {
                margin: 30px 0;
                font-size: 17px;
            }

            .coinpayConain {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                border: 1px solid #fff;
                border-radius: 50px;
                padding: 10px 20px;
                overflow: hidden;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .seperator {
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.28);
            }

            .buyContain {
                flex: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .buyBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 10px;
                    background-color: #13cc68;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(14, 145, 75);
                    }
                    &:active {
                        background-color: rgb(7, 82, 42);
                    }
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }

        @media (max-width: 915px) {
            .productContain {
                margin-right: 0;
                margin-bottom: 10px;

                .headerContain {
                    .product {
                        flex: 1;
                        font-size: 14px;
                    }
                    .quantity {
                        flex: 0.35;
                        font-size: 14px;
                    }

                    .price {
                        flex: 0.35;
                        font-size: 14px;
                    }

                    .delete {
                        flex: 0.35;
                        font-size: 14px;
                    }
                }
            }

            .paymentContain {
                // display: none;
                flex: 1;
            }
        }
    }
    @media (max-width: 915px) {
        .shoppingContain {
            padding: 0 10px;
        }
    }

    .purchaseHistory {
        width: calc(100% - 60px);
        padding: 10px 30px;
        background-color: #22262e;
        margin: 2rem 0;
        border-radius: 10px;

        .listItem {
            display: flex;

            .span {
                display: flex;
                align-items: center;
                margin: 50px 0;
            }
        }
        .listItemm {
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-weight: 200;
        }

        .showAll {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin: 15px 0;
            font-weight: 500;
            cursor: pointer;

            .showAllIcon {
                margin-left: 5px;
            }
        }
    }
    @media (max-width: 915px) {
        .purchaseHistory {
            width: calc(100% - 20px);
            padding: 10px;
        }
    }
}
