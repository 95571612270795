.planCard {
    background-color: #23252b;
    width: 230px;
    border-radius: 30px;
    box-shadow: 0px 0px 3px #555555;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 15px;

    .cardHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10%;
        position: relative;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;

        .triangle {
            width: 30px;
            height: 20px;
            clip-path: polygon(100% 0, 0 0, 50% 100%);
            position: absolute;
            bottom: -20px;
        }
    }

    .bodyContain {
        width: calc(100% - 20px);
        height: 25rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 10px;
        margin-top: 20px;

        .first {
            flex: 0.3;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .price {
                color: #fff;
                font-size: 25px;
                font-weight: 500;
            }
            .other {
                color: #fff;
                font-size: 15px;
            }

            .line {
                width: 50px;
                height: 2px;
                background-color: #13cc68;
                position: absolute;
                bottom: 8px;
            }
        }
        .second {
            flex: 1;
            display: flex;
            flex-direction: column;

            .infoContain {
                flex: 1;
                display: flex;

                .infoLeft {
                    flex: 0.4;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .infoIcon {
                        font-size: 18px;
                        color: #fff;
                    }
                }
                .infoMiddle {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    color: #fff;
                }
                .infoRight {
                    flex: 0.7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 11px;
                    color: #fff;

                    .infoIcon {
                        font-size: 18px;
                    }
                }
            }

            .father {
                display: flex;
                justify-content: center;
                margin-top: 5px;

                .countContain {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 80px;
                    border-radius: 15px;
                    border: 2px solid #fff;
                    color: #fff;
                    padding: 5px 10px;

                    .addBtn {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        color: #fff;
                    }

                    .count {
                        font-size: 15px;
                    }
                }
            }
        }
        .third {
            flex: 0.3;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 130px;
                height: 30px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #13cc68;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                padding: 5px;
                font-size: 13px;

                &:hover {
                    background-color: rgb(14, 145, 75);
                }

                &:disabled {
                    cursor: not-allowed;
                    background-color: rgb(35, 107, 69);
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: white;
                    color: #13cc68;
                    margin-left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .btn:active {
                background-color: rgb(7, 82, 42);
            }
        }

        .topicContain {
            display: flex;
            align-items: center;
            color: #13cc68;
            margin-bottom: 10px;

            .topic {
                font-size: 15px;
                font-weight: 600;
                color: white;
                margin-left: 15px;
            }
        }

        .body {
            color: white;
            font-size: 15px;
        }
    }
}

@media (max-width: 800px) {
    .planCard {
        background-color: #23252b;
        width: 220px;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 15px;

        .cardHeader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 10%;
            position: relative;
            color: #fff;
            font-weight: 500;

            .triangle {
                width: 30px;
                height: 20px;
                clip-path: polygon(100% 0, 0 0, 50% 100%);
                position: absolute;
                bottom: -20px;
            }
        }

        .bodyContain {
            width: calc(100% - 20px);
            height: 25rem;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 10px;
            margin-top: 20px;

            .first {
                flex: 0.3;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .price {
                    color: #fff;
                    font-size: 25px;
                    font-weight: 500;
                }
                .other {
                    color: #fff;
                    font-size: 15px;
                }

                .line {
                    width: 50px;
                    height: 2px;
                    background-color: #13cc68;
                    position: absolute;
                    bottom: 8px;
                }
            }
            .second {
                flex: 1;
                display: flex;
                flex-direction: column;

                .infoContain {
                    flex: 1;
                    display: flex;

                    .infoLeft {
                        flex: 0.4;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .infoIcon {
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                    .infoMiddle {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        color: #fff;
                    }
                    .infoRight {
                        flex: 0.7;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 11px;
                        color: #fff;

                        .infoIcon {
                            font-size: 18px;
                        }
                    }
                }

                .father {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;

                    .countContain {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 80px;
                        border-radius: 15px;
                        border: 2px solid #fff;
                        color: #fff;
                        padding: 5px 10px;

                        .addBtn {
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            color: #fff;
                        }

                        .count {
                            font-size: 15px;
                        }
                    }
                }
            }
            .third {
                flex: 0.3;
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    width: 130px;
                    height: 30px;
                    overflow: hidden;
                    border-radius: 30px;
                    background-color: #13cc68;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    color: #fff;
                    border: none;
                    padding: 5px;
                    font-size: 13px;

                    &:hover {
                        background-color: rgb(14, 145, 75);
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #13cc68;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .btn:active {
                    background-color: rgb(7, 82, 42);
                }
            }

            .topicContain {
                display: flex;
                align-items: center;
                color: #13cc68;
                margin-bottom: 10px;

                .topic {
                    font-size: 15px;
                    font-weight: 600;
                    color: white;
                    margin-left: 15px;
                }
            }

            .body {
                color: white;
                font-size: 15px;
            }
        }
    }
}
