.container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .ticketHistory1 {
        flex: 1 1 160px;
        margin: 0 20px;
        background-image: linear-gradient(to right bottom, #1a1c20, #1a191c, #181618, #161414, #121111);
        border-radius: 15px;

        .listItem {
            display: flex;
            flex-direction: column;
            padding: 50px 0;

            .spanHash {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0088ea;
            }
            .span {
                display: flex;
                align-items: center;
            }
        }
    }

    .ticketHistory2 {
        flex: 0.3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;

        .ticketStatus {
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
            height: 70%;
            border-radius: 15px;
            background-image: linear-gradient(to bottom, #1a1c20, #1a191c, #181618, #161414, #121111);
            padding: 20px 30px;

            .statusTxt {
                color: #fff;
                font-size: 20px;
                text-align: center;
                margin: 10px 0;
            }

            .combine {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .count {
                    display: flex;
                    width: 25px;
                    height: 25px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                    background-color: #13cc68;
                }
            }

            .seperator {
                width: 100%;
                height: 1px;
                margin: 10px 0;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        .sendBtn {
            width: 70%;
            height: 40px;
            background-color: #13cc68;
            overflow: hidden;
            margin-right: 10px;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            border: none;
        }
        .sendBtn:hover {
            background-color: #0d7c41;
        }
        .sendBtn:active {
            background-color: #084e29;
        }
    }
    
    @media (max-width: 915px) {
        .ticketHistory1 {
            margin: 10px;
        }
        .ticketHistory2 {
            flex: 1;
            margin: 10px;
        }
    }
}
