.payDialog {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 3rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;

    .dialogContain {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33rem;
        height: 33rem;
        border-radius: 10px;
        background-image: linear-gradient(to right bottom, #223f36, #1c3531, #192a2b, #172122, #141718);
        position: relative;
        overflow: hidden;
        padding: 15px;

        .headerContain {
            display: flex;

            .headerTitle {
                flex: 1;
                display: flex;
                flex-direction: column;
                
                .title {
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                .content {
                    font-size: 15px;
                }
            }
            .haderTimmer {
                flex: 0.3;
                display: flex;
                justify-content: center;
                align-items: center;

                .timmer {
                    width: 100px;
                }
            }
        }

        .qr {
            width: 30%;
            height: 30%;
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .footerFirst {
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;

            .tether {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
            .addressTxt {
                width: 100%;
                font-size: 15px;
            }
            .copyTxt {
                color: #13CC68;
                margin-right: 5px;
            }
            .icon {
                align-self: flex-end;
            }
        }

        .footerSecond {
            display: flex;
            flex-direction: column;
            width: 100%;
            cursor: pointer;

            .amountTitle {
                color: #A7A7A7;
                font-size: 17px;
            }
            .amountTxt { width: 100%;
                color: #fff;
                font-size: 17px;
            }
        }
        
        .btnContain {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 30px;
                border-radius: 10px;
                background-color: #262626;
                color: #9A9A9A;
                margin-right: 5px;
                cursor: pointer;
                outline: none;
                border: none;

                &:hover {
                    background-color: #1d1a1a;
                }

                &:active {
                    background-color: #141414;
                }
            }
        }
    }
    @media (max-width: 800px) {
        .dialogContain {
            width: 80%;
            height: 55%;

            .qr {
                width: 50%;
                height: 45%;
            }
        }
    }
    @media (max-height: 590px) {
        .dialogContain {
            width: 80%;
            height: 90%;

            .qr {
                width: 20%;
                height: 50%;
            }
        }
    }

    .seperator {
        width: 100%;
        height: 2px;
        background-color: #515151;
        margin: 10px;
    }
}

.payDialog.close {
    display: none;
}

@media (max-height: 400px) {
    .payDialog {
        padding-top: 10px;
    }
}
