.chatDialog {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: flex-end;
    right: 30px;
    bottom: 15px;
    transition: transform 0.8s ease-in-out;
    width: 300px;
    height: 400px;
    border-radius: 15px;
    background-image: linear-gradient(to right bottom, #223f36, #1c3531, #192a2b, #172122, #141718);
    overflow: hidden;
    overflow-y: auto;
    z-index: 500;

    .footer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        align-self: bottom;
        background-color: red;
    }
}
.chatDialog.close {
    transform: translateX(150%);
}