.localAlert {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 4rem);
    padding: 20px;
    background: rgb(26, 30, 32);
    background: linear-gradient(90deg, rgba(26, 30, 32, 1) 0%, rgba(18, 17, 17, 1) 100%);
    border-radius: 10px;
    margin: 0.5rem 2rem;
    overflow: hidden;
    transition: transform 1s, visibility 1s, opacity 1s linear;
    position: relative;

    .iconContain {
        display: flex;
        align-items: flex-start;
        margin-right: 10px;
        margin-bottom: 10px;

        .alertIcon {
            color: #ffa800;
        }
    }

    .txtContain {
        flex: 1 1 160px;
        display: flex;
        flex-direction: column;

        .alertTxt {
            font-size: 16px;
        }
    }

    .iconCloseContain {
        position: absolute;
        top: 15px;
        right: 15px;

        .closeIcon {
            cursor: pointer;
        }
        .closeIcon:hover {
            background-color: #686868;
        }
        .closeIcon:active {
            background-color: #1f1f1f;
        }
    }
}
.localAlert.close {
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
}
