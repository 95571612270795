.sidebar {
    flex: 1;
    height: 100vh;
    background-color: #1a1c20;
    position: sticky;
    top: 0;

    .sidebarWrapper {
        padding: 0 20px 20px 20px;
        color: #555;

        .sidebarMenu {
            .logoContain {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                height: 20%;
                overflow: hidden;

                .logo {
                    width: 50px;
                    height: 40px;
                    background-color: #26262e;
                    padding: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .infoContain {
                    display: none;
                }
                @media (max-width: 915px) {
                    .infoContain {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        padding: 0 10px;
                        margin: 10px 0;
                        overflow: hidden;

                        .name {
                            font-size: 11px;
                            font-weight: 500;
                            color: #fff;
                        }
                        .email {
                            display: inline-block;
                            width: 120px;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                            font-size: 11px;
                            font-weight: 200;
                            color: rgb(156, 156, 156);
                        }
                    }
                }
            }

            .sidebarList {
                height: calc(100vh - 130px);
                list-style: none;
                padding: 5px;
                overflow: hidden;
                overflow-y: auto;

                .sidebarListItem {
                    padding: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    color: #767676;
                    margin-bottom: 10px;

                    .sidebarIcon {
                        padding: 8px;
                        background-image: linear-gradient(to right bottom, #363a3f, #2f3338, #292c30, #222529, #1c1e22);
                        border-radius: 10px;
                        margin-right: 10px;
                    }
                }
                .sidebarListItem.active,
                .sidebarListItem:hover {
                    color: #fff;
                    font-weight: 800;

                    .sidebarIcon {
                        color: #13cc68;
                    }
                }

                .exitListItem {
                    padding: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    color: #fff;

                    .exitIcon {
                        padding: 8px;
                        background-image: linear-gradient(to right bottom, #363a3f, #2f3338, #292c30, #222529, #1c1e22);
                        border-radius: 10px;
                        margin-right: 10px;
                        color: #c11e28;
                    }
                }
            }
            @media (max-width: 915px) {
                .sidebarList {
                    height: calc(100vh - 130px);
                    overflow: hidden;
                    overflow-y: auto;
                }
            }
        }
    }

    .closeBtn {
        display: none;
    }

    .mask {
        display: none;
    }
}
@media (max-width: 915px) {
    .sidebar {
        width: 70%;
        position: absolute;
        overflow: hidden;
        z-index: 1002;
        transition: width 0.3s ease-in-out;

        .closeBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10px;
            right: 10px;
            color: #fff;
            cursor: pointer;
        }

        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: red;
            position: absolute;
            z-index: 700;
            transition: width 0.3s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .mask.close {
            width: 0;
        }
    }

    .sidebar.close {
        flex: 0;
        width: 0;
    }
}
