.MuiCardMedia-media {
    object-fit: inherit;
    height: 140px;
}

.topCard {
    display: flex;
    justify-content: center;
    position: relative;

    .roboImgContain {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: calc(120px / 2);
        position: absolute;
        z-index: 900;
        top: -60px;
        background: rgb(125, 13, 193);
        background: linear-gradient(0deg, rgba(125, 13, 193, 0.5746673669467788) 0%, rgba(0, 71, 255, 1) 100%);
        cursor: pointer;

        .icon {
            color: #fff;
        }
    }
}

.roboCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .nameTxt {
        font-size: 20px;
        color: white;
        margin-top: 50px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .coinLabel {
        display: flex;
        margin-bottom: 20px;

        .label {
            flex: 1;
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 14px !important;
            color: #fff;
            overflow: hidden;
        }
        .value {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px !important;
            color: #13cc68;
            overflow: hidden;

            .dot {
                width: 10px;
                height: 10px;
                margin-right: 20px;
            }
        }
    }

    .bottomContain {
        display: flex;
        width: 100%;

        .bottom {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconL {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                color: #13cc68;
                cursor: pointer;
                background-color: #1a1c20;
                padding: 10px;
            }
            .iconR {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                font-size: 30px !important;
                color: #ea0f0f;
                cursor: pointer;
                background-color: #1a1c20;
                padding: 10px;
            }
        }
    }

    .settingContain {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        top: 0;
        background-color: #fff;
        transition: all 1s;
        padding: 20px 0;
        background: rgb(26, 28, 32);
        background: linear-gradient(90deg, rgba(26, 28, 32, 1) 0%, rgba(18, 17, 17, 1) 100%);

        .main {
            display: flex;
            flex-direction: column;
            padding: 0 30px;

            .settingTopic {
                font-size: 16px;
                color: #fff;
                text-align: center;
                margin-bottom: 20px;
            }
            .settingTxt {
                font-size: 14px;
                color: #fff;
                margin-bottom: 5px;
            }
            .input {
                width: 100%;
                height: 25px;
                margin-bottom: 15px;
                background-color: transparent;
                border-radius: 5px;
                outline: none;
                border-style: solid;
                border-color: #303030;
                font-size: 16px;
                color: #fff;
            }
            .input:focus {
                border-color: #13CC68;
            }
        }
        .bottom {
            display: flex;
            margin-top: 50px;

            .uploadTxt {
                width: 100%;
                font-size: 14px;
                color: #0088EA;
                cursor: pointer;
            }
            .saveTxt {
                width: 100%;
                font-size: 14px;
                color: #13CC68;
                cursor: pointer;
                text-align: right;
            }
        }
    }
    .settingContain.close {
        transform: translateY(-100%);
    }
}
