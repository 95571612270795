.newsCard {
    background-color: #23252b;
    width: 25%;
    border-radius: 30px;
    box-shadow: 0px 0px 3px #555555;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    margin-bottom: 10px;
    margin-right: 10px;

    .imgNewsContain {
        width: 100%;
        height: 180px;
        overflow: hidden;

        .imgNews {
            width: 100%;
            height: 100%;
        }
    }

    .newsTopic {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        color: #13cc68;
    }

    .newsBold {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        color: #fff;
        font-weight: 500;
    }

    .news {
        width: 100%;
        text-align: left;
        color: #ababab;
        margin-top: 10px;
    }

    .newsFooter {
        display: flex;
        width: 100%;
        margin-top: 10px;

        .newsLeft {
            width: 100%;
            text-align: left;
            color: #dbdbdb;
        }
        .newsRight {
            width: 100%;
            text-align: right;
            color: #dbdbdb;
        }
    }
}

@media (max-width: 800px) {
    .newsCard {
        background-color: #23252b;
        width: 270px;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 30px;

        .imgNewsContain {
            width: 100%;
            height: 180px;
            overflow: hidden;

            .imgNews {
                width: 100%;
                height: 100%;
            }
        }

        .newsTopic {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            color: #13cc68;
        }

        .newsBold {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            color: #fff;
            font-weight: 500;
        }

        .news {
            width: 100%;
            text-align: left;
            color: #ababab;
            margin-top: 10px;
        }

        .newsFooter {
            display: flex;
            width: 100%;
            margin-top: 10px;

            .newsLeft {
                width: 100%;
                text-align: left;
                color: #dbdbdb;
            }
            .newsRight {
                width: 100%;
                text-align: right;
                color: #dbdbdb;
            }
        }
    }
}
