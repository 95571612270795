.googleAuth {
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;

    .dialogContain {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        height: 90%;
        border-radius: 10px;
        background-color: #1d2023;
        overflow: hidden;
        position: relative;

        .topicTxt {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
        }
        .titleTxt {
            text-align: center;
            font-size: 18px;
            margin-top: 10px;
        }

        .mainAuth {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 90%;
            margin-top: 2.5rem;
            overflow: hidden;
            padding-bottom: 5px;
            overflow: hidden;

            .count {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                border-radius: 20px;
                align-items: center;
                padding: 10px;
                background-color: #0581db;
                overflow: hidden;
            }

            .googleAuthImg {
                width: 120px;
                height: 120px;
                margin: 1rem 0;
                border-radius: 10px;
            }

            .combine {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .googlePlay {
                    width: 122px;
                    overflow: hidden;

                    .googlePlayImg {
                        width: 130px;
                        height: 60px;
                    }
                }

                .appleStore {
                    width: 200px;
                    height: 82px;
                    overflow: hidden;

                    .appleStoreImg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .code {
                display: flex;
                align-items: center;
                margin-top: 10px;
                font-weight: 500;
                cursor: pointer;

                .codeTxt {
                    display: inline-block;
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    color: rgb(156, 156, 156);
                }
            }

            .varifyContain {
                width: 260px;
                margin-top: 10px;
            }
            .varifyChar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                background-color: #fff;
                margin: 5px;
                border-radius: 10px;
                color: #000;
                font-size: 18px;
                font-weight: 500;
            }
            .charSelected {
                border-style: solid;
                border-width: 3px;
                border-color: #0581db;
            }
            @media (max-width: 915px) {
                .varifyContain {
                    width: 100%;
                }
                .varifyChar {
                    width: 20px;
                    height: auto;
                }
            }
        }
        @media (max-width: 915px) {
            .mainAuth {
                height: 45%;
                overflow-y: auto;
            }
        }

        .first {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.5s ease-in-out;
            padding: 2rem 10px 0;
        }
        .first.close {
            transform: translateX(-100%);
        }

        .second {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.5s ease-in-out;
            transform: translateX(100%);
            padding: 2rem 10px 0;
        }
        .second.close {
            transform: translateX(-100%);
        }
        .second.open {
            transform: translateX(0);
        }

        .third {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.5s ease-in-out;
            transform: translateX(100%);
            padding: 2rem 10px 0;
        }
        .third.close {
            transform: translateX(-100%);
        }
        .third.open {
            transform: translateX(0);
        }

        .next {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 30px;
            right: 20px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: #13cc68;
            font-size: 17px;

            .icon {
                font-size: 20px;
                margin-left: 10px;
            }
        }
        .prev {
            position: absolute;
            bottom: 30px;
            left: 20px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: #fff;
            font-size: 17px;
        }
    }

    @media (max-width: 800px) {
        .dialogContain {
            width: 90%;
        }
    }
}

.googleAuth.close {
    display: none;
}
