.shoppingItem {
    display: flex;
    align-items: center;
    padding: 30px 0;
    overflow: hidden;
    transition: transform 1s, visibility 1s, opacity 1s linear;

    .productItem {
        flex: 1;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        color: #fff;

        .itemIcon {
            margin-right: 15px;
        }
        .itemTitleContain {
            display: flex;
            flex-direction: column;
            color: #fff;
            white-space: nowrap;
            margin-right: 5px;

            .itemTitle1 {
                font-size: 14px;
                font-weight: 600;
            }
            .itemTitle2 {
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }
    .quantityItem {
        flex: 0.3;
        font-size: 15px;
        color: #fff;
        margin-right: 5px;
    }
    .priceItem {
        flex: 0.5;
        font-size: 15px;
        color: #fff;
        margin-right: 5px;
    }
    .deleteItem {
        flex: 0.1;
        font-weight: 600;
        font-size: 20px;
        color: #fff;

        .closeIcon {
            transform: rotate(45deg);
            color: #767676;
            cursor: pointer;
        }
    }

    @media (max-width: 915px) {
        .productItem {
            flex: 1;
        }
        .quantityItem {
            flex: 0.3;
        }
        .priceItem {
            flex: 0.3;
        }
        .deleteItem {
            flex: 0.3;
        }
    }
}
.shoppingItem.close {
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
}
