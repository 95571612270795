.aboutDialog {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;
    z-index: 1200;

    .dialogContain {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: 50%;
        border-radius: 10px;
        background-color: #1d2023;
        position: relative;

        .contentContain {
            display: flex;
            flex-direction: column;
            width: calc(100% - 10rem);
            height: calc(100% - 40px);
            padding: 20px 5rem;
            overflow: hidden;

            .topic {
                font-size: 30px;
                margin-bottom: 20px;
                color: #0581db;
            }

            .main {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 50%;
                overflow: hidden;
                overflow-y: auto;

                .lineContain {
                    color: #0581db;
                    font-size: 15px;
                    margin-bottom: 10px;

                    .line {
                        color: #fff;
                    }
                }
            }

            .closeBtn {
                width: 100px;
                height: 30px;
                align-self: flex-end;
                background-color: #13cc68;
                overflow: hidden;
                color: #fff;
                border-radius: 10px;
                cursor: pointer;
                border: none;
                margin-top: 10px;
            }
            .closeBtn:hover {
                background-color: #0d7c41;
            }
            .closeBtn:active {
                background-color: #084e29;
            }
        }
        @media (max-width: 800px) {
            .contentContain {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                padding: 20px;
            }
        }

        .closeContain {
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
        }
    }
}
