.app {
    display: flex;
    width: 100vw;
    overflow: hidden;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.link {
    text-decoration: none;
    color: inherit;
    cursor: auto;
}
