.verifyDialog {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;

    .dialogContain {
        width: 20rem;
        height: 20rem;
        border-radius: 10px;
        background-color: #1d2023;
        position: relative;
        overflow: hidden;

        .closeBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 10px;
            right: 10px;
            color: #fff;
            cursor: pointer;
            z-index: 1000;
        }

        .dialog {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: transform 0.5s;
            padding: 30px 20px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;

            .title {
                font-size: 19px;
                font-weight: 500;
                margin-bottom: 2rem;
                text-align: center;
            }

            .mainTxt {
                font-size: 16px;
                text-align: center;
            }

            .phoneTxt {
                color: #13cc68;
                text-align: center;
                margin-bottom: 1rem;
            }

            @media (max-height: 300px) {
                .title {
                    font-size: 15px;
                    margin-bottom: 1rem;
                }

                .mainTxt {
                    font-size: 12px;
                }

                .phoneTxt {
                    font-size: 12px;
                    margin-bottom: 0.5rem;
                }
            }

            .inpTitleContain {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 5px;

                .inpTitle {
                    font-size: 11px;
                    margin-left: 5px;
                }
            }

            .varifyContain {
                width: 150px;
                margin-bottom: 10px;
            }
            .varifyChar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                background-color: #fff;
                margin: 5px;
                border-radius: 10px;
                color: #000;
                font-size: 18px;
                font-weight: 500;
            }
            .charSelected {
                border-style: solid;
                border-width: 3px;
                border-color: #0581db;
            }

            .sendAgain {
                font-size: 11px;
                color: #0088ea;
                cursor: pointer;
                margin-bottom: 2rem;
            }

            .btnValid {
                width: 100px;
                height: 35px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #13cc68;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                padding: 5px;

                &:hover {
                    background-color: rgb(14, 145, 75);
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: white;
                    color: #13cc68;
                    margin-left: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .btnValid:active {
                background-color: rgb(7, 82, 42);
            }

            .passContain {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 5px;
                border-style: solid;
                border-width: 2px;
                border-color: rgba(19, 204, 104, 0.3);
                border-radius: 10px;
                margin-bottom: 10px;

                .loginInput {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: #fff;
                }
                .loginInput::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: rgba(255, 255, 255, 0.5);
                    opacity: 1; /* Firefox */
                }
                .loginInput:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: rgba(255, 255, 255, 0.5);
                }
                .loginInput::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: rgba(255, 255, 255, 0.5);
                }

                .showPass {
                    cursor: pointer;
                }
            }
        }

        .loadContain {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
        .loadContain.close {
            display: none;
        }
    }
    @media (max-width: 800px) {
        .dialogContain {
            width: 80%;
            height: 55%;
        }
    }
    @media (max-height: 590px) {
        .dialogContain {
            width: 80%;
            height: 90%;
        }
    }
}
.verifyDialog.close {
    display: none;
}
@media (max-height: 400px) {
    .verifyDialog {
        padding-top: 10px;
    }
}