.loaderPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;
}
.loaderPage.close {
    display: none;
}