.setting {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    color: #fff;
    padding: 0 20px;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .topFirst {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;

        .left {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                font-size: 60px;
                color: #fff;
            }

            .titleContain {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .mainTitle {
                    font-size: 28px;
                    font-weight: 500;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;

            .rightContent {
                display: flex;
                align-items: center;
                overflow: hidden;

                .buyBtn {
                    width: 100px;
                    height: 40px;
                    background-color: #13cc68;
                    overflow: hidden;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 20px;
                    cursor: pointer;
                    border: none;
                }
                .buyBtn:hover {
                    background-color: #0d7c41;
                }
                .buyBtn:active {
                    background-color: #084e29;
                }

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }

                    .addProductItem > label {
                        color: gray;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .addProductItem > input {
                        padding: 10px;
                    }

                    .addProductItem > select {
                        padding: 10px;
                    }
                }
                .notifContain {
                    display: flex;
                    flex: 0.5;

                    .combine {
                        position: relative;
                        cursor: pointer;

                        .icon {
                            padding: 5px;
                            background-color: #393a3f;
                            border-radius: 10px;
                            margin-right: 10px;
                            font-size: 20px !important;
                            color: #fff;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 3px;
                            right: 15px;
                            border-radius: 50%;
                            background-color: #13cc68;
                        }
                    }
                }
            }
        }

        .leftMobile {
            display: none;
        }
        .middleMobile {
            display: none;
        }
        .rightMobile {
            display: none;
        }
    }
    @media (max-width: 915px) {
        .topFirst {
            padding: 20px 0;

            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .leftMobile {
                flex: 0.2;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 0 10px;

                .menuBtn {
                    cursor: pointer;
                }
            }
            .middleMobile {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
            .rightMobile {
                flex: 0.2;
                display: flex;
                padding: 0 10px;

                .profileContain {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
            }
        }
    }

    .accountContain {
        display: flex;
        flex-direction: column;

        .title {
            color: #fff;
            font-weight: 600;
            font-size: 28px;
        }

        .account {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            margin-top: 15px;

            .imgContain {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 40px 0;
                margin-right: 5px;

                .imgAccountContain {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 120px;
                    position: relative;
                    overflow: hidden;
                    padding-bottom: 15px;

                    .imgAccount {
                        width: calc(100% - 6px);
                        height: calc(100% - 6px);
                        border-radius: 50%;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        border: 3px solid #fff;
                    }

                    .selectImg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                    }

                    .uploadBtn {
                        width: 85%;
                        height: 25px;
                        background-color: #13cc68;
                        overflow: hidden;
                        color: #fff;
                        border-radius: 20px;
                        cursor: pointer;
                        border: none;
                        position: absolute;
                        bottom: 5px;
                        padding: 0 10px;
                        font-size: 12px;
                    }
                    .uploadBtn:hover {
                        background-color: #0d7c41;
                    }
                    .uploadBtn:active {
                        background-color: #084e29;
                    }
                }
            }

            .infoContain {
                display: flex;
                flex-direction: column;
                width: 15rem;
                margin: 15px 20px;

                .info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .infoHeader {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        .headerIcon {
                            margin-right: 5px;
                        }
                        .headerTitle {
                            width: 100%;
                            font-size: 16px;
                        }
                        @media (max-width: 915px) {
                            .headerTitle {
                                font-size: 14px;
                            }
                        }

                        .verified {
                            width: 100px;
                            padding: 5px;
                            font-size: 10px;
                            text-align: center;
                            border-radius: 20px;
                            background: rgb(19, 204, 104);
                            background: linear-gradient(
                                90deg,
                                rgba(19, 204, 104, 0.65) 0%,
                                rgba(19, 204, 104, 0.18) 100%
                            );
                        }
                        .notVerified {
                            width: 100px;
                            padding: 5px;
                            font-size: 10px;
                            text-align: center;
                            border-radius: 20px;
                            background: rgb(255, 215, 7);
                            background: linear-gradient(
                                90deg,
                                rgba(255, 215, 7, 0.75) 0%,
                                rgba(233, 143, 38, 0.27) 100%
                            );
                        }
                    }

                    .infoInput {
                        color: #fff;
                        background-color: rgba(21, 21, 21, 0.44);
                        outline: none;
                        border: 1px solid rgba(19, 205, 106, 0.3);
                        border-radius: 10px;
                        font-size: 13px;
                        padding: 10px;
                    }

                    .phoneContain {
                        width: 100%;
                        padding-right: 100px;
                        background-color: transparent;
                    }
                    .buttonClass {
                        background-color: rgba(21, 21, 21, 0.44);
                        outline: none;
                        border: 1px solid rgba(19, 205, 106, 0.3);
                        border-radius: 10px;
                    }
                    .inputClass {
                        width: auto;
                        height: auto;
                        padding: 5px;
                        margin-left: 50px;
                        background-color: rgba(21, 21, 21, 0.44);
                        color: #fff;
                        font-size: 13px;
                        outline: none;
                        border: 1px solid rgba(19, 205, 106, 0.3);
                        border-radius: 10px;
                    }
                    .dropdownClass {
                        width: auto;
                        background-color: rgba(21, 21, 21, 0.44);

                        input {
                            width: auto;
                        }
                    }
                    .searchClass {
                        display: flex;
                        justify-content: center;
                        padding: 5px;
                        width: auto;
                        background-color: rgb(77, 77, 77);
                    }

                    .verifyContain {
                        display: flex;
                        justify-content: flex-end;

                        .verifyBtn {
                            color: #0088ea;
                            font-size: 12px;
                            margin-top: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
            @media (max-width: 915px) {
                .infoContain {
                    margin: 15px 5px;
                }
            }
        }
    }

    .purchaseHistory {
        width: calc(100% - 60px);
        padding: 10px 30px;
        background-color: #22262e;
        margin: 2rem 0;
        border-radius: 10px;

        .listItem {
            display: flex;

            .span {
                display: flex;
                align-items: center;
                margin: 50px 0;
            }
        }

        .showAll {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin: 15px 0;
            font-weight: 500;
            cursor: pointer;

            .showAllIcon {
                margin-left: 5px;
            }
        }
    }
    @media (max-width: 915px) {
        .purchaseHistory {
            width: calc(100% - 20px);
            padding: 10px;
        }
    }
}
