.home {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 130px);
    // background: url("../../images/back.png");
    // background-size: cover;
    background-image: linear-gradient(to right bottom, #223f36, #1c3531, #192a2b, #172122, #141718);
    overflow: hidden;
    overflow-y: auto;
    padding-top: 130px;

    .mainContain {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 150px;

        .topContain {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            padding-bottom: 80px;

            .top {
                display: flex;

                .left {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding-top: 70px;

                    .anim {
                        display: flex;
                        width: 250px;
                        height: 3px;
                        background: rgb(0, 221, 141);
                        background: linear-gradient(
                            90deg,
                            rgba(0, 221, 141, 1) 10%,
                            rgba(31, 111, 255, 1) 50%,
                            rgba(0, 221, 141, 1) 90%
                        );
                        margin-bottom: 10px;
                        overflow: hidden;

                        .engulf1 {
                            flex: 1;
                            display: flex;
                            overflow: hidden;
                            .engulf {
                                width: 100%;
                                height: 100%;
                                background-color: black;
                                transform: translatex(100%);
                                transition: transform 0s ease-in-out;
                            }
                            .engulf.close {
                                transform: translateX(-100%);
                                transition: transform 1.3s ease-in-out;
                            }
                        }

                        .engulf2 {
                            flex: 1;
                            display: flex;
                            overflow: hidden;
                            .engulf {
                                width: 100%;
                                height: 100%;
                                background-color: black;
                                transform: translatex(-100%);
                                transition: transform 0s ease-in-out;
                            }
                            .engulf.close {
                                transform: translateX(100%);
                                transition: transform 1.3s ease-in-out;
                            }
                        }
                    }

                    .span1 {
                        font-size: 45px;
                        font-weight: 400;
                        color: white;
                    }

                    .span2 {
                        font-size: 60px;
                        font-weight: 900;
                        color: white;
                    }

                    .off {
                        display: flex;
                        width: 200px;
                        height: 25px;
                        background-color: #383c3e;
                        border-radius: 30px;
                        overflow: hidden;
                        margin-top: 15px;
                        color: white;
                        align-items: center;
                        padding-right: 10px;
                        font-size: 13px;

                        .offMount {
                            background-color: #f7fcf7;
                            border-radius: 30px;
                            width: 30%;
                            height: 100%;
                            padding: 0 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            color: black;
                            font-weight: 700;
                            font-size: 13px;
                        }
                    }

                    .span3 {
                        font-size: 15px;
                        color: white;
                        margin-top: 10px;
                    }

                    .btn {
                        width: 130px;
                        height: 30px;
                        overflow: hidden;
                        border-radius: 30px;
                        background-color: #13cc68;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        color: #fff;
                        border: none;
                        padding: 5px;
                        margin-top: 60px;

                        &:hover {
                            background-color: rgb(14, 145, 75);
                        }

                        .icon {
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background-color: white;
                            color: #13cc68;
                            margin-left: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .btn:active {
                        background-color: rgb(7, 82, 42);
                    }
                }

                .right {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    img {
                        width: 80%;
                        height: 100%;
                    }
                }
                @media (max-width: 900px) {
                    .right {
                        flex: 0;
                    }
                }
            }

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translateY(-50%) rotate(90deg);
                transition: transform 0.5s ease-in-out;
                color: #13cc68;
                z-index: 500;
            }
            .arrow.close {
                transform: translateY(50%) rotate(90deg);
            }
        }

        .middleContain {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            align-items: center;
            padding-top: 150px;
            padding-bottom: 50px;

            .span1 {
                display: flex;
                align-items: center;
                width: 350px;
                height: 100px;
                font-size: 28px;
                font-weight: 400;
                color: white;
                margin-bottom: 20px;
                background: url("../../images/fxrobott.png");
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .signContain {
                height: 80px;
                overflow: hidden;

                .sign {
                    width: 5px;
                    height: 80px;
                    background-color: #13cc68;
                    transform: translateY(-100%);
                    transition: transform 0s ease-in-out;
                }
                .sign.close {
                    height: 80px;
                    transform: translateY(100%);
                    transition: transform 1.5s ease-in-out;
                }
            }

            .middleBottom {
                width: 100%;
                margin-top: 202px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: stretch;

                .card {
                    background-color: #23252b;
                    width: 200px;
                    border-radius: 30px;
                    box-shadow: 0px 0px 3px #555555;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    overflow: hidden;
                    margin: 0 20px;
                    margin-bottom: 10px;

                    .topicContain {
                        display: flex;
                        align-items: center;
                        color: #13cc68;
                        margin-bottom: 10px;

                        .topic {
                            font-size: 15px;
                            font-weight: 600;
                            color: white;
                            margin-left: 15px;
                        }
                    }

                    .body {
                        color: white;
                        font-size: 15px;
                    }
                }
            }
        }

        .middleContain2 {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            align-items: center;
            padding-top: 150px;
            padding-bottom: 50px;

            .span1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 350px;
                height: 100px;
                font-size: 28px;
                font-weight: 400;
                color: white;
                margin-bottom: 20px;
                background: url("../../images/fxrobott.png");
                text-align: center;
            }

            .signContain {
                height: 80px;
                overflow: hidden;

                .sign {
                    width: 5px;
                    height: 80px;
                    background-color: #13cc68;
                    transform: translateY(-100%);
                    transition: transform 0s ease-in-out;
                }
                .sign.close {
                    height: 80px;
                    transform: translateY(100%);
                    transition: transform 1.5s ease-in-out;
                }
            }

            .middleBottom {
                width: 100%;
                margin-top: 202px;
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: wrap;
                justify-content: center;
                align-content: stretch;
            }
        }

        .middleContain3 {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            align-items: center;
            justify-content: center;

            .middle3 {
                width: 70%;
                height: 250px;
                border-radius: 30px;
                background-color: rgba(129, 129, 129, 0.1);
                box-shadow: 0px 0px 3px #555555;
                position: relative;
                margin-top: 8rem;
                padding: 20px;

                .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    .appIconContain {
                        display: flex;

                        .appIcon {
                            width: 30px;
                            height: 40px;
                            margin-right: 10px;
                        }
                    }
                    .txtContain {
                        display: flex;
                        margin-top: 10px;

                        .solid1 {
                            color: #fff;
                            margin-right: 5px;
                        }
                        .solid2 {
                            color: #fff;
                            font-weight: 500;
                        }
                        .solid3 {
                            color: #fff;
                            margin-right: 5px;
                            font-size: 30px;
                            font-weight: 500;
                        }
                        .solid4 {
                            color: #13cc68;
                            font-weight: 500;
                            font-size: 30px;
                            margin-right: 5px;
                        }
                    }
                }

                .imgPhone {
                    width: 150px;
                    height: 450px;
                    position: absolute;
                    z-index: 1200;
                    right: 40px;
                    top: -150px;
                    z-index: 900;
                }
                @media (max-width: 915px) {
                    .imgPhone {
                        width: 0;
                    }
                }

                .inner {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    filter: blur(4px);
                    -webkit-filter: blur(4px);
                    position: relative;
                    z-index: 999;

                    .img1 {
                        width: 80px;
                        height: 80px;
                        position: absolute;
                        bottom: 200px;
                        left: -40px;
                        z-index: 900;
                    }
                    .img2 {
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: -280px;
                        left: 50%;
                        z-index: 900;
                    }
                    .img3 {
                        position: absolute;
                        top: -200px;
                        right: -20px;
                        z-index: 900;
                    }
                }
            }
        }

        .middleContain4 {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            align-items: center;
            padding-top: 150px;
            padding-bottom: 50px;

            .span1 {
                display: flex;
                align-items: center;
                width: 350px;
                height: 100px;
                font-size: 28px;
                font-weight: 400;
                color: white;
                margin-bottom: 20px;
                background: url("../../images/fxrobott.png");
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .signContain {
                height: 80px;
                overflow: hidden;

                .sign {
                    width: 5px;
                    height: 80px;
                    background-color: #13cc68;
                    transform: translateY(-100%);
                    transition: transform 0s ease-in-out;
                }
                .sign.close {
                    height: 80px;
                    transform: translateY(100%);
                    transition: transform 1.5s ease-in-out;
                }
            }

            .middleBottom {
                width: 100%;
                margin-top: 202px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: stretch;
            }
        }

        .middleContain5 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 300px);
            align-items: center;
            margin-top: 10px;

            .blog {
                font-size: 23px;
                font-weight: 400;
                color: white;
            }

            .blogIcon {
                font-size: 30px;
                color: #13cc68;
                margin-left: 10px;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 50px 100px;
        background-color: #101016;
        margin-top: 30px;

        .footerTop {
            display: flex;

            .footerInner1 {
                flex: 0.4;
                display: flex;
                flex-direction: column;
                padding-right: 10px;

                .footerLogo {
                    width: 50px;
                    height: 40px;
                }
                .footerTxt {
                    width: 100%;
                    color: #fff;
                    margin-top: 10px;
                    font-weight: 200;
                    font-size: 23px;
                }
                .footerTxtBold {
                    width: 100%;
                    color: #fff;
                    font-weight: 600;
                    font-size: 23px;
                }
                .social {
                    display: flex;
                    margin-top: 30px;

                    .socialIcon {
                        font-size: 15px;
                        color: #fff;
                        margin-right: 15px;
                    }
                    .leftSocial {
                        flex: 0.5;
                    }
                    .rightSocial {
                        flex: 1;
                        display: flex;
                    }
                }
            }
            .footerInner2 {
                flex: 0.3;
                display: flex;
                flex-direction: column;
                padding-right: 10px;

                .footerTopic {
                    width: 100%;
                    color: #fff;
                    margin-bottom: 10px;
                    font-weight: 700;
                    font-size: 13px;
                }
                .footerTxt {
                    width: 100%;
                    color: #fff;
                    margin-bottom: 5px;
                    font-weight: 400;
                    font-size: 13px;
                }
            }
            .footerInner3 {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .payment {
                    width: 100%;
                    color: #fff;
                    font-size: 25px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .paymentBtn {
                    width: 100px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 10px;
                    background-color: #1a1a20;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    color: #fff;
                    border: none;
                    padding: 5px;
                    margin-top: 20px;

                    &:hover {
                        background-color: #3b3b47;
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                    }
                }
                .paymentBtn:active {
                    background-color: #121213;
                }
            }
        }
        .footerBottom {
        }
    }

    @media (max-width: 800px) {
        .mainContain {
            flex: 0;
            padding: 0 25px;

            .topContain {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                padding-bottom: 80px;

                .top {
                    display: flex;

                    .left {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding-top: 70px;

                        .anim {
                            display: flex;
                            width: 250px;
                            height: 3px;
                            background: rgb(0, 221, 141);
                            background: linear-gradient(
                                90deg,
                                rgba(0, 221, 141, 1) 10%,
                                rgba(31, 111, 255, 1) 50%,
                                rgba(0, 221, 141, 1) 90%
                            );
                            margin-bottom: 10px;
                            overflow: hidden;

                            .engulf1 {
                                flex: 1;
                                display: flex;
                                overflow: hidden;
                                .engulf {
                                    width: 100%;
                                    height: 100%;
                                    background-color: black;
                                    transform: translatex(100%);
                                    transition: transform 0s ease-in-out;
                                }
                                .engulf.close {
                                    transform: translateX(-100%);
                                    transition: transform 1.3s ease-in-out;
                                }
                            }

                            .engulf2 {
                                flex: 1;
                                display: flex;
                                overflow: hidden;
                                .engulf {
                                    width: 100%;
                                    height: 100%;
                                    background-color: black;
                                    transform: translatex(-100%);
                                    transition: transform 0s ease-in-out;
                                }
                                .engulf.close {
                                    transform: translateX(100%);
                                    transition: transform 1.3s ease-in-out;
                                }
                            }
                        }

                        .span1 {
                            font-size: 45px;
                            font-weight: 400;
                            color: white;
                        }

                        .span2 {
                            font-size: 60px;
                            font-weight: 900;
                            color: white;
                        }

                        .off {
                            display: flex;
                            width: 200px;
                            height: 25px;
                            background-color: #383c3e;
                            border-radius: 30px;
                            overflow: hidden;
                            margin-top: 15px;
                            color: white;
                            align-items: center;
                            padding-right: 10px;
                            font-size: 13px;

                            .offMount {
                                background-color: #f7fcf7;
                                border-radius: 30px;
                                width: 30%;
                                height: 100%;
                                padding: 0 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 10px;
                                color: black;
                                font-weight: 700;
                                font-size: 13px;
                            }
                        }

                        .span3 {
                            font-size: 15px;
                            color: white;
                            margin-top: 10px;
                        }

                        .btn {
                            width: 130px;
                            height: 30px;
                            overflow: hidden;
                            border-radius: 30px;
                            background-color: #13cc68;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            color: #fff;
                            border: none;
                            padding: 5px;
                            margin-top: 60px;

                            &:hover {
                                background-color: rgb(14, 145, 75);
                            }

                            .icon {
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                background-color: white;
                                color: #13cc68;
                                margin-left: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        .btn:active {
                            background-color: rgb(7, 82, 42);
                        }
                    }

                    .right {
                        flex: 0;
                    }
                }

                .arrow {
                    display: none;
                }
            }

            .middleContain {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                align-items: center;
                padding-top: 150px;
                padding-bottom: 50px;

                .span1 {
                    display: flex;
                    align-items: center;
                    width: auto;
                    height: 100px;
                    font-size: 28px;
                    font-weight: 400;
                    color: white;
                    margin-bottom: 20px;
                    background: url("../../images/fxrobott.png");
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                .middleBottom {
                    width: 100%;
                    margin-top: 202px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: stretch;

                    .card {
                        background-color: #23252b;
                        width: 220px;
                        border-radius: 30px;
                        box-shadow: 0px 0px 3px #555555;
                        display: flex;
                        flex-direction: column;
                        padding: 20px;
                        overflow: hidden;
                        margin: 0 20px;
                        margin-bottom: 20px;

                        .topicContain {
                            display: flex;
                            align-items: center;
                            color: #13cc68;
                            margin-bottom: 10px;

                            .topic {
                                font-size: 15px;
                                font-weight: 600;
                                color: white;
                                margin-left: 15px;
                            }
                        }

                        .body {
                            color: white;
                            font-size: 15px;
                        }
                    }
                }
            }

            .middleContain2 {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                align-items: center;
                padding-top: 150px;
                padding-bottom: 50px;

                .span1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: 100px;
                    font-size: 28px;
                    font-weight: 400;
                    color: white;
                    margin-bottom: 20px;
                    background: url("../../images/fxrobott.png");
                    text-align: center;
                }

                .middleBottom {
                    width: 100%;
                    margin-top: 202px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: stretch;
                }
            }

            .middleContain3 {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                align-items: center;
                justify-content: center;

                .middle3 {
                    width: 80%;
                    height: 250px;
                    border-radius: 30px;
                    background-color: rgba(129, 129, 129, 0.1);
                    box-shadow: 0px 0px 3px #555555;
                    position: relative;
                    margin-top: 8rem;
                    padding: 20px;

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: 100%;

                        .appIconContain {
                            display: flex;

                            .appIcon {
                                width: 30px;
                                height: 40px;
                                margin-right: 10px;
                            }
                        }
                        .txtContain {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 10px;

                            .solid1 {
                                color: #fff;
                                margin-right: 5px;
                            }
                            .solid2 {
                                color: #fff;
                                font-weight: 500;
                            }
                            .solid3 {
                                color: #fff;
                                margin-right: 5px;
                                font-size: 30px;
                                font-weight: 500;
                            }
                            .solid4 {
                                color: #13cc68;
                                font-weight: 500;
                                font-size: 30px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .imgPhone {
                        display: none;
                    }

                    .inner {
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;
                        filter: blur(4px);
                        -webkit-filter: blur(4px);
                        position: relative;
                        z-index: 999;

                        .img1 {
                            display: none;
                        }
                        .img2 {
                            display: none;
                        }
                        .img3 {
                            display: none;
                        }
                    }
                }
            }

            .middleContain4 {
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
                align-items: center;
                padding-top: 150px;
                padding-bottom: 50px;

                .span1 {
                    display: flex;
                    align-items: center;
                    width: auto;
                    height: 100px;
                    font-size: 28px;
                    font-weight: 400;
                    color: white;
                    margin-bottom: 20px;
                    background: url("../../images/fxrobott.png");
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                .signContain {
                    height: 80px;
                    overflow: hidden;

                    .sign {
                        width: 5px;
                        height: 80px;
                        background-color: #13cc68;
                        transform: translateY(-100%);
                        transition: transform 0s ease-in-out;
                    }
                    .sign.close {
                        height: 80px;
                        transform: translateY(100%);
                        transition: transform 1.5s ease-in-out;
                    }
                }

                .middleBottom {
                    width: 100%;
                    margin-top: 202px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: stretch;
                    justify-content: center;
                }
            }

            .middleContain5 {
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% - 50px);
                align-items: center;
                margin-top: 10px;

                .blog {
                    font-size: 23px;
                    font-weight: 400;
                    color: white;
                }

                .blogIcon {
                    font-size: 30px;
                    color: #13cc68;
                    margin-left: 10px;
                }
            }
        }

        .footer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 50px 20px;
            background-color: #101016;
            margin-top: 30px;

            .footerTop {
                display: flex;
                flex-direction: column;

                .footerInner1 {
                    display: flex;
                    flex-direction: column;
                    padding-right: 10px;
                    margin-bottom: 20px;

                    .footerLogo {
                        width: 50px;
                        height: 40px;
                    }
                    .footerTxt {
                        width: 100%;
                        color: #fff;
                        margin-top: 10px;
                        font-weight: 200;
                        font-size: 23px;
                    }
                    .footerTxtBold {
                        width: 100%;
                        color: #fff;
                        font-weight: 600;
                        font-size: 23px;
                    }
                    .social {
                        display: flex;
                        margin-top: 30px;

                        .socialIcon {
                            font-size: 15px;
                            color: #fff;
                            margin-right: 15px;
                        }
                        .leftSocial {
                            flex: 0.5;
                        }
                        .rightSocial {
                            flex: 1;
                            display: flex;
                        }
                    }
                }
                .footerInner2 {
                    display: flex;
                    flex-direction: column;
                    padding-right: 10px;
                    margin-bottom: 20px;

                    .footerTopic {
                        width: 100%;
                        color: #fff;
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 13px;
                    }
                    .footerTxt {
                        width: 100%;
                        color: #fff;
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
                .footerInner3 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 20px;

                    .payment {
                        width: 100%;
                        color: #fff;
                        font-size: 25px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }

                    .paymentBtn {
                        width: 100px;
                        height: 40px;
                        overflow: hidden;
                        border-radius: 10px;
                        background-color: #1a1a20;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        color: #fff;
                        border: none;
                        padding: 5px;
                        margin-top: 20px;

                        &:hover {
                            background-color: #3b3b47;
                        }

                        .icon {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: cover;
                        }
                    }
                    .paymentBtn:active {
                        background-color: #121213;
                    }
                }
            }
            .footerBottom {
            }
        }
    }
}
