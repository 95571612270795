.container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    .ticketWrite1 {
        flex: 1;
        display: flex;
        flex-direction: column;

        .contain {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 2rem;

            .part {
                display: flex;
                flex-direction: column;
                flex: 0.4;
                margin-bottom: 10px;
            }

            .part2 {
                display: flex;
                flex-direction: column;
                flex: 0.3;
                margin-bottom: 10px;
            }
            .part3 {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
            }

            
            @media (max-width: 915px) {
                .part {
                    flex: 0.4 0.4 100%;
                }
                
                .part2 {
                    flex: 0.3 0.3 100%;
                }
            }

            .iconContain {
                display: flex;
                align-items: center;
                color: #fff;
                margin-bottom: 10px;
                
                @media (max-width: 915px) {
                    .txtTitle {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }
                }

                .icon {
                    margin-right: 5px;
                }
            }

            .inputFirst {
                color: #fff;
                font-family: ru;
                border-style: solid;
                border-width: 2px;
                padding: 5px;
                border-radius: 10px;
                border-color: rgba(19, 204, 104, 0.3);
                background-color: rgba(21, 21, 21, 0.8);
            }
            .inputArea {
                color: #fff;
                font-family: ru;
                border-style: solid;
                border-width: 2px;
                padding: 5px;
                border-radius: 10px;
                border-color: rgba(19, 204, 104, 0.3);
                background-color: rgba(21, 21, 21, 0.8);
                resize: none;
                outline: none;
                font-size: 18px;
            }
        }

        .footer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 10px;

            .sendBtn {
                width: 100px;
                height: 30px;
                background-color: #13cc68;
                overflow: hidden;
                color: #fff;
                border-radius: 10px;
                cursor: pointer;
                border: none;
            }
            .sendBtn:hover {
                background-color: #0d7c41;
            }
            .sendBtn:active {
                background-color: #084e29;
            }

            .cancelBtn {
                width: 100px;
                height: 30px;
                background-color: #919497;
                overflow: hidden;
                color: #fff;
                border-radius: 10px;
                cursor: pointer;
                border: none;
                margin-right: 10px;
            }
            .cancelBtn:hover {
                background-color: #0a582e;
            }
            .cancelBtn:active {
                background-color: #073e21;
            }
        }
        @media (max-width: 915px) {
            .footer {
                justify-content: flex-start;
            }
        }

        .inputContain {
            display: flex;
            height: 30px;
            align-items: center;
            width: calc(100% - 20px);
            padding: 5px;
            border-style: solid;
            border-width: 1px;
            border-color: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            margin-bottom: 10px;

            .input {
                width: 100%;
                color: #fff;
                font-size: 15px;
                background-color: transparent;
                outline: none;
                border: none;
            }
            .input::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #575757;
                opacity: 1; /* Firefox */
            }
            .input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #575757;
            }
            .input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #575757;
            }

            .fileBtn {
                width: 100px;
                height: 100%;
                background-color: #13cc68;
                overflow: hidden;
                margin-right: 20px;
                color: #fff;
                border-radius: 10px;
                cursor: pointer;
                border: none;
            }
            .fileBtn:hover {
                background-color: #0d7c41;
            }
            .fileBtn:active {
                background-color: #084e29;
            }

            .fileTxt {
                font-size: 15px;
                color: #575757;
            }
        }

        .select {
            border-color: #fff;
            outline: none;
        }
        .input {
            color: #fff;
            font-family: ru;
            border-style: solid;
            border-width: 2px;
            padding: 5px;
            border-radius: 10px;
            border-color: rgba(19, 204, 104, 0.3);
            background-color: rgba(21, 21, 21, 0.8);
        }
    }

    .ticketWrite2 {
        flex: 0.8;
        padding: 2rem 0 0 2rem;

        .contain {
            display: flex;
            height: 15rem;
            flex-direction: column;
            background-image: linear-gradient(to bottom, #1a1c20, #1a191c, #181618, #161414, #121111);
            border-radius: 10px;
            padding: 15px 20px;

            .iconContain {
                display: flex;
                align-items: center;
                color: #fff;
                margin-bottom: 20px;

                .icon {
                    margin-right: 5px;
                }
            }

            .main {
                width: 100%;
                height: 100%;
                overflow: hidden;
                overflow-y: auto;

                .ticketContain {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;
                    cursor: pointer;

                    .idd {
                        color: #0088ea;
                        font-size: 12px;
                        margin-right: 5px;
                        display:inline-block;
                        width:50px;
                        white-space: nowrap;
                        overflow:hidden !important;
                        text-overflow: ellipsis;
                    }
                    .id {
                        color: #0088ea;
                        font-size: 12px;
                        margin-right: 5px;
                    }
                    .topic {
                        width: 120px;
                        color: #fff;
                        font-size: 12px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    @media (max-width: 915px) {
        // .ticketWrite1 {
        //     margin: 10px;
        // }
        .ticketWrite2 {
            width: 60%;
            padding: 0;
            padding-left: 10px;
        }
    }
}
