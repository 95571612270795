body {
  margin: 0;
  font-family: ru;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-family: ru;
}
span {
  font-family: ru;
}
li {
  font-family: ru;
}
h1 {
  font-family: ru;
}
button {
  font-family: ru;
}
input {
  font-family: ru;
}
.link {
  font-family: ru;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ru';
  src        : local('ru'), url(./fonts/Rubik-Regular.ttf) format('truetype');
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
      to bottom,
      rgba(126, 126, 126, 0.6) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    border-radius: 20px;
}