.header {
    color: white;
    font-size: 14px;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1100;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.9) 50%);
    transition: all 1s ease;
    padding: 40px 8%;
    overflow: hidden;
    display: flex;

    .contain {
        width: calc(100% - 16%);
        overflow: hidden;
        display: flex;

        .left {
            flex: 0.7;

            img {
                width: 50px;
                height: 40px;
                cursor: pointer;
            }
        }

        .right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            overflow: hidden;

            .btn {
                height: 30px;
                padding: 0 10px;
                overflow: hidden;
                border-radius: 30px;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                margin: 0 5px;

                &:hover {
                    background-color: rgba(14, 145, 75, 0.7);
                }
            }
            .btn:active {
                background-color: rgba(7, 82, 42, 0.7);
            }

            .rightContent {
                display: flex;
                overflow: hidden;

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        display:inline-block;
                        width:100px;
                        white-space: nowrap;
                        overflow:hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        display:inline-block;
                        width:100px;
                        white-space: nowrap;
                        overflow:hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
            }

            .seperate {
                width: 2px;
                height: 20px;
                background-color: #636363;
                margin: 0 10px;
            }

            .regBtn {
                width: 70px;
                height: 30px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #13cc68;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;

                &:hover {
                    background-color: #0e914b;
                }
            }
            .regBtn:active {
                background-color: #07522a;
            }

            // @media only screen and (max-width: 600px) {
            //     .regBtn {
            //         width: 60px;
            //         height: 30px;
            //         overflow: hidden;
            //         border-radius: 15px;
            //         background-color: #13cc68;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         cursor: pointer;
            //         color: #fff;
            //         border: none;

            //         &:hover {
            //             background-color: #0e914b;
            //         }
            //     }
            //     .regBtn:active {
            //         background-color: #07522a;
            //     }
            // }
        }

        .mobileLeft {
            display: none;
        }
        .mobileMiddle {
            display: none;
        }
        .mobileRight {
            display: none;
        }
    }
}
@media (max-width: 800px) {
    .header {
        padding: 20px;

        .contain {
            width: calc(100% - 40px);
            overflow: hidden;
            display: flex;

            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .mobileLeft {
                display: flex;
                flex: 0.2;

                .btnContainer {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;

                    .btn {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 2rem;
                        height: 2rem;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                        padding: 0;
                        z-index: 10;
                        cursor: pointer;

                        &:focus {
                            outline: none;
                        }

                        div {
                            width: 2rem;
                            height: 0.25rem;
                            background-color: rgb(255, 255, 255);
                            border-radius: 10px;
                            transition: all 0.3s linear;
                            position: relative;
                            transform-origin: 1px;
                        }

                        .first {
                            transform: translate(10%, -230%) rotate(45deg);
                        }
                        .firstClose {
                            transform: rotate(0deg);
                        }

                        .second {
                            transform: translate(10%, 180%) rotate(-45deg);
                        }
                        .secondClose {
                            transform: rotate(0);
                            margin-top: 10px;
                        }
                    }

                    .menu {
                        font-size: 40px !important;
                    }
                }
            }
            .mobileMiddle {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;

                img {
                    width: 50px;
                    height: 40px;
                }
            }
            .mobileRight {
                display: flex;
                flex: 0.2;
                color: transparent;
            }
        }
    }
}
