.withdrawDialog {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 80, 80, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    overflow-y: auto;

    .dialogContain {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 40px;
        border-radius: 10px;
        background-color: #1d2023;
        position: relative;
        overflow: hidden;

        .iconClose {
            position: absolute;
            top: 20px;
            left: 20px;
            color: #ffa800;
            cursor: pointer;
        }

        .topic {
            font-size: 23px;
            margin-bottom: 20px;
        }
        .icon {
            color: #ffa800;
            margin-bottom: 3rem;
        }

        .mainContain {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            .inputTitleContain {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .wallet {
                    margin-right: 10px;
                }
                .inputTitle {
                    font-size: 16px;
                }
            }

            .inputContain {
                padding: 10px 20px;
                border-radius: 10px;
                background-color: #151515;
                margin-bottom: 10px;

                .input {
                    width: 100%;
                    background-color: transparent;
                    font-size: 15px;
                    color: #fff;
                    outline: none;
                    border: none;
                }
                .input::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #373738;
                    opacity: 1; /* Firefox */
                }
                .input:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #373738;
                }
                .input::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #373738;
                }
            }

            .checkContain {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                cursor: pointer;

                .checkLabel {
                    color: #fff;
                    font-size: 13px;
                    margin-left: 10px;
                }
            }
        }
        
        .btn {
            overflow: hidden;
            border-radius: 30px;
            background-color: #373737;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.17);
            border: none;
            padding: 10px 20px;
            margin-top: 60px;
            font-size: 17px;

            &:hover {
                background-color: #5f5f5f;
            }
            &:active {
                background-color: #464646;
            }
            &:disabled {
                cursor: not-allowed;
            }

            .iconBtn {
                margin-left: 1rem;
                color: #fff;
            }
        }
    }
    @media (max-width: 800px) {
        .dialogContain {
            width: 70%;
        }
    }
}

.withdrawDialog.close {
    display: none;
}
