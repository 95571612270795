.dashboard {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .top {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 0;

        .topFirst {
            display: flex;
            margin-bottom: 30px;

            .left {
                display: flex;
                align-items: center;
                flex: 1;
                padding: 0 20px;

                .leftContent {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    .title {
                        font-size: 30px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        font-size: 18px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 1;
                padding: 0 20px;

                .rightContent {
                    display: flex;
                    overflow: hidden;

                    .profileContain {
                        flex: 0.5;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .imgContain {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: solid #fff;

                            .img {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    -webkit-filter: grayscale(100%);
                                    filter: grayscale(100%);
                                }
                            }
                        }
                    }
                    .infoContain {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 5;
                        color: #fff;
                        padding: 0 10px;
                        overflow: hidden;

                        .name {
                            display: inline-block;
                            width: 100px;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                            font-size: 11px;
                            font-weight: 500;
                            color: #fff;
                        }
                        .email {
                            display: inline-block;
                            width: 100px;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                            font-size: 11px;
                            font-weight: 200;
                            color: rgb(156, 156, 156);
                        }
                    }
                    .notifContain {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 0.5;
                        padding: 10px;

                        .combine {
                            position: relative;
                            cursor: pointer;

                            .icon {
                                padding: 5px;
                                background-color: #393a3f;
                                border-radius: 10px;
                                margin-right: 10px;
                                font-size: 20px !important;
                                color: #fff;
                            }
                            .dot {
                                width: 8px;
                                height: 8px;
                                position: absolute;
                                top: 3px;
                                right: 15px;
                                border-radius: 50%;
                                background-color: #13cc68;
                            }
                        }
                    }
                }
            }

            .leftMobile {
                display: none;
            }
            .middleMobile {
                display: none;
            }
            .rightMobile {
                display: none;
            }
        }
        @media (max-width: 915px) {
            .topFirst {
                flex: 0.6;

                .left {
                    display: none;
                }
                .right {
                    display: none;
                }

                .leftMobile {
                    flex: 0.2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    padding: 0 10px;

                    .menuBtn {
                        cursor: pointer;
                    }
                }
                .middleMobile {
                    flex: 1;
                    display: block;
                    color: transparent;
                }
                .rightMobile {
                    flex: 0.2;
                    display: flex;
                    padding: 0 10px;

                    .profileContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .imgContain {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: solid #fff;

                            .img {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    -webkit-filter: grayscale(100%);
                                    filter: grayscale(100%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .topSecond {
            display: flex;
            padding: 0 10px;
            flex-wrap: wrap;
            overflow: hidden;

            .card {
                display: flex;
                flex: 1 1 160px;
                height: 30px;
                border-radius: 20px;
                margin: 10px;
                overflow: hidden;
                background-image: linear-gradient(to right, #2f3237, #2a2d32, #25282c, #212327, #1c1e22);
                border: solid #fff;
                padding: 30px 0;

                .left {
                    flex: 0.4;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        color: #0ad16e;
                    }
                }
                .right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 5px;

                    .title {
                        font-size: 18px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .status {
                        font-size: 13px;
                        font-weight: 300;
                        color: #b3b3b3;
                    }
                }
            }
        }
    }

    .middle {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .left {
            flex: 1 1 160px;
            display: flex;
            flex-direction: column;
            padding: 10px;
            overflow: hidden;
            flex-wrap: wrap;

            .chartImg {
                width: 100%;
            }

            .chartContain {
                display: flex;
                flex-direction: column;
                padding: 20px;
                width: calc(100% - 40px);
                // height: 30rem;
                background-color: #2f3035;
                border-radius: 10px;
                margin-bottom: 20px;
                overflow: hidden;

                .topChart {
                    flex: 0.2;
                    display: flex;
                    margin-bottom: 30px;

                    .leftChart {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-weight: 500;
                        font-size: 18px;
                    }
                    .rightChart {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .tabs {
                            color: #fff;
                            font-weight: 500;
                            font-size: 13px;
                            margin: 0 10px;
                            cursor: pointer;
                        }
                    }
                }
                .bottom {
                    flex: 1;
                }
            }
            .pieChartContain {
                display: flex;
                flex-wrap: wrap;

                .pieChart {
                    flex: 1 1 160px;
                    border-radius: 10px;
                    background: rgb(26, 26, 32);
                    background: linear-gradient(90deg, rgba(26, 26, 32, 1) 0%, rgba(18, 17, 17, 1) 100%);
                    margin-right: 10px;
                    margin-bottom: 10px;
                    overflow: hidden;
                    padding: 20px;

                    .pieTop {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .pieTitle {
                            flex: 1;
                            font-size: 20px;
                            font-weight: 500;
                            color: #fff;
                        }
                        .cat {
                            flex: 1;
                            font-size: 15px;
                            font-weight: 300;
                            color: #fff;
                            text-align: right;
                        }
                    }

                    .pieBottom {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .labeContain {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 55px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 11px;
                            color: #fff;
                            text-align: center;
                            overflow: hidden;
                        }
                    }

                    .pieBottomText {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        text-align: center;
                        color: #757575;
                        font-weight: 300;
                        font-size: 15;
                    }
                }
                .pieChart2 {
                    flex: 1 1 160px;
                    border-radius: 10px;
                    background: rgb(26, 28, 32);
                    background: linear-gradient(90deg, rgba(26, 28, 32, 1) 0%, rgba(18, 17, 17, 1) 100%);
                    overflow: hidden;
                    padding: 20px;
                    margin-right: 10px;

                    .pieTop {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .pieTitle {
                            flex: 1;
                            font-size: 20px;
                            font-weight: 500;
                            color: #fff;
                        }
                    }
                    .pieBottom {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .labeContain {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 11px;
                            color: #fff;
                            text-align: center;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .right {
            flex: 0.8 0.8 160px;
            flex-direction: column;
            padding: 10px;

            .appContain {
                display: flex;
                flex-direction: column;
                background: rgb(26, 28, 32);
                background: linear-gradient(90deg, rgba(26, 28, 32, 1) 0%, rgba(18, 17, 17, 0) 100%);
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                padding: 20px 10px;
                color: #fff;

                .iconContain {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;

                    .icon {
                        margin-right: 3px;
                        font-size: 30px !important;
                    }

                    .iconTitle {
                        font-size: 11px;
                    }
                }
                .txtLine {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;

                    .txt {
                        font-size: 30px;
                    }

                    .txt2 {
                        font-size: 36px;
                        margin-right: 5px;
                    }

                    .txtGreen {
                        font-size: 36px;
                        margin-right: 8px;
                        color: #13cc68;
                    }
                }

                .obj1 {
                    width: 100px;
                    height: 80px;
                    position: absolute;
                    right: 40px;
                    bottom: 5px;
                    z-index: 900;
                }
                .obj2 {
                    width: 100px;
                    height: 90px;
                    position: absolute;
                    right: -30px;
                    top: 50px;
                    z-index: 1000;
                }
                .obj3 {
                    width: 100px;
                    height: 90px;
                    position: absolute;
                    right: -40px;
                    top: -20px;
                    z-index: 900;
                }
            }
            @media (max-width: 915px) {
                .appContain {
                    display: none;
                }
            }

            .robotContain {
                display: flex;
                flex-direction: column;
                background: rgb(26, 28, 32);
                background: linear-gradient(90deg, rgba(26, 28, 32, 1) 0%, rgba(18, 17, 17, 1) 100%);
                color: #fff;
                padding: 15px;
                border-radius: 10px;
                margin-top: 10px;
                overflow: auto;

                .topTxt {
                    font-size: 23px;
                    font-weight: 500;
                }
                .expireTxt {
                    font-size: 16px;
                    color: #767676;
                    margin: 16px 0;
                }

                .robotColl {
                    display: flex;
                    flex-direction: column;
                    margin-top: 30px;

                    .collContain {
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 15px;

                        .coll {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }

                    .contentContain {
                        display: flex;
                        width: 100%;
                        margin-bottom: 10px;
                        align-items: center;

                            .leftContent {
                                flex: 1;
                                display: flex;
                                flex-direction: column;

                                .robotTxt {
                                    font-size: 18px;
                                    font-weight: lighter;
                                    display: inline-block;
                                    width: 130px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                }
                            }
                            .rightContent {
                                flex: 0.5;
                                display: flex;
                                flex-direction: column;

                                .deactiveTxt {
                                    font-size: 16px;
                                    color: #f15e5e;
                                }

                                .dot {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                    }

                    .loadContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                    }
                }
            }
            @media (max-width: 915px) {
                .robotContain {
                    .topTxt {
                        font-size: 18px;
                    }
                    .robotTxt {
                        font-size: 15px;
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }
                    .expireTxt {
                        font-size: 14p;
                    }
                    .deactiveTxt {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
