.verify {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    color: #fff;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .topFirst {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                color: #fff;
            }

            .titleContain {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .mainTitle {
                    font-size: 28px;
                    font-weight: 500;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            padding: 0 20px;

            .rightContent {
                display: flex;
                align-items: center;
                overflow: hidden;

                .buyBtn {
                    width: 100px;
                    height: 40px;
                    background-color: #13cc68;
                    overflow: hidden;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 20px;
                    cursor: pointer;
                    border: none;
                }
                .buyBtn:hover {
                    background-color: #0d7c41;
                }
                .buyBtn:active {
                    background-color: #084e29;
                }

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
                .notifContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 0.5;
                    padding: 10px;

                    .combine {
                        position: relative;
                        cursor: pointer;

                        .icon {
                            padding: 5px;
                            background-color: #393a3f;
                            border-radius: 10px;
                            margin-right: 10px;
                            font-size: 20px !important;
                            color: #fff;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 3px;
                            right: 15px;
                            border-radius: 50%;
                            background-color: #13cc68;
                        }
                    }
                }
            }
        }

        .leftMobile {
            display: none;
        }
        .middleMobile {
            display: none;
        }
        .rightMobile {
            display: none;
        }
    }
    @media (max-width: 915px) {
        .topFirst {
            padding: 20px 0;
            
            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .leftMobile {
                flex: 0.2;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 0 10px;

                .menuBtn {
                    cursor: pointer;
                }
            }
            .middleMobile {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
            .rightMobile {
                flex: 0.2;
                display: flex;
                padding: 0 10px;

                .profileContain {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
            }
        }
    }

    .mainContain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;

        .videoImg {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #292b2e;
            margin-bottom: 10px;
        }

        .recordBtn {
            width: 130px;
            height: 30px;
            background-color: #13cc68;
            overflow: hidden;
            margin-bottom: 10px;
            color: #fff;
            border-radius: 20px;
            cursor: pointer;
            border: none;
        }
        .recordBtn:hover {
            background-color: #0d7c41;
        }
        .recordBtn:active {
            background-color: #084e29;
        }

        .recGuide {
            max-width: 32rem;
            text-align: center;
            margin-bottom: 10px;
            color: #fff;
            font-size: 15px;
            overflow: hidden;
        }

        .someGuide {
            max-width: 38rem;
            padding: 2rem;
            border: 2px solid #13cc68;
            color: #fff;
            font-size: 18px;
            border-radius: 10px;
            margin-bottom: 3rem;
        }

        .main {
            display: flex;
            width: 42rem;
            overflow: hidden;

            .mainLeft {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                .titleTxt {
                    color: #fff;
                    font-size: 18px;
                    margin-bottom: 5px;
                }

                .inputContain {
                    display: flex;
                    height: 30px;
                    align-items: center;
                    width: calc(100% - 20px);
                    padding: 5px;
                    border: 1px solid #fff;
                    border-radius: 10px;
                    margin-bottom: 10px;

                    .input {
                        width: 100%;
                        color: #fff;
                        font-size: 15px;
                        background-color: transparent;
                        outline: none;
                        border: none;
                    }
                    .input::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #575757;
                        opacity: 1; /* Firefox */
                    }
                    .input:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #575757;
                    }
                    .input::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #575757;
                    }

                    .fileBtn {
                        width: 100px;
                        height: 100%;
                        background-color: #13cc68;
                        overflow: hidden;
                        margin-right: 20px;
                        color: #fff;
                        border-radius: 10px;
                        cursor: pointer;
                        border: none;
                    }
                    .fileBtn:hover {
                        background-color: #0d7c41;
                    }
                    .fileBtn:active {
                        background-color: #084e29;
                    }

                    .fileTxt {
                        font-size: 15px;
                        color: #575757;
                    }
                }

                .fileExplainTxt {
                    color: #fff;
                    font-size: 13px;
                }
            }
            .mainRight {
                flex: 0.5;
                display: flex;
                flex-direction: column;
                background-color: #fff;
            }
        }
        @media (max-width: 915px) {
            .main {
                flex-direction: column;
                width: auto;
            }
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 3rem;

            .uploadBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                padding: 20px;
                background-color: #13cc68;
                overflow: hidden;
                margin-bottom: 10px;
                color: #fff;
                border-radius: 20px;
                cursor: pointer;
                border: none;

                .uploadIcon {
                    margin-left: 10px;
                }
            }
            .uploadBtn:hover {
                background-color: #0d7c41;
            }
            .uploadBtn:active {
                background-color: #084e29;
            }
        }
    }
}
@media (max-width: 915px) {
    .verify {
        padding: 0 10px;
    }
}
