.menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    position: fixed;
    transition: height 0.4s ease-in-out, opacity 0.8s linear;
    background-color: #000;
    z-index: 1000;

    .seperator {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .main {
        display: flex;
        height: 70vh;
        flex-direction: column;
        padding: 0 10px;
        overflow: hidden;
        overflow-y: auto;

        .item {
            display: flex;
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px 0;
            cursor: pointer;
            color: #fff;
            font-size: 20px;
        }
        .authItem {
            display: flex;
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px 0;
            color: #fff;
            font-size: 20px;

            .innerItem {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .innerItem:focus {
                background-color: rgba(14, 145, 75, 0.7);
            }

            .innerSeperate {
                width: 1px;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
.menu.close {
    height: 0;
    opacity: 0;
}