.securityTab {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    color: #fff;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .topFirst {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                font-size: 60px;
                color: #fff;
            }

            .titleContain {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .mainTitle {
                    font-size: 28px;
                    font-weight: 500;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            padding: 0 20px;

            .rightContent {
                display: flex;
                align-items: center;
                overflow: hidden;

                .buyBtn {
                    width: 100px;
                    height: 40px;
                    background-color: #13cc68;
                    overflow: hidden;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 20px;
                    cursor: pointer;
                    border: none;
                }
                .buyBtn:hover {
                    background-color: #0d7c41;
                }
                .buyBtn:active {
                    background-color: #084e29;
                }

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
                .notifContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 0.5;
                    padding: 10px;

                    .combine {
                        position: relative;
                        cursor: pointer;

                        .icon {
                            padding: 5px;
                            background-color: #393a3f;
                            border-radius: 10px;
                            margin-right: 10px;
                            font-size: 20px !important;
                            color: #fff;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 3px;
                            right: 15px;
                            border-radius: 50%;
                            background-color: #13cc68;
                        }
                    }
                }
            }
        }

        .leftMobile {
            display: none;
        }
        .middleMobile {
            display: none;
        }
        .rightMobile {
            display: none;
        }
    }
    @media (max-width: 915px) {
        .topFirst {
            padding: 20px 0;

            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .leftMobile {
                flex: 0.2;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 0 10px;

                .menuBtn {
                    cursor: pointer;
                }
            }
            .middleMobile {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
            .rightMobile {
                flex: 0.2;
                display: flex;
                padding: 0 10px;

                .profileContain {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
            }
        }
    }

    .securityContain {
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        .optionContain {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: stretch;
        }
    }

    .top {
        width: calc(100% - 20rem);
        margin: 100px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        padding: 0 10rem;

        .card {
            background-color: #23252b;
            width: 300px;
            height: 80px;
            border-radius: 10px;
            box-shadow: 0px 0px 3px #555555;
            display: flex;
            padding: 20px;
            margin: 0 20px 30px 20px;
            padding: 0px 5px;
            position: relative;
            align-items: center;
            overflow: hidden;

            .left {
                display: flex;
                align-items: center;

                .img {
                    width: 70px;
                    height: 70px;
                    padding: 5px;
                }
            }
            .center {
                flex: 1;
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 15px;
                margin: 5px 10px;
                block-size: max-content;
            }
            .right {
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0088ea;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                border: none;

                .txtChange {
                    color: #0088ea;
                }
                .txtChange.close {
                    display: none;
                }

                .loadContain {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .loadContain.close {
                    display: none;
                }
            }

            .topicContain {
                display: flex;
                align-items: center;
                color: #13cc68;
                margin-bottom: 10px;

                .topic {
                    font-size: 15px;
                    font-weight: 600;
                    color: white;
                    margin-left: 15px;
                }
            }

            .body {
                color: white;
                font-size: 15px;
            }

            .twoStepContain {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 20px;
                background: rgb(19, 204, 104);
                background: linear-gradient(90deg, rgba(19, 204, 104, 0.6) 0%, rgba(19, 204, 104, 0) 100%);
                border-radius: 15px;
                color: #00ff75;
                position: absolute;
                top: -45px;
                left: 0;
            }
            .twoStepContain.close {
                display: none;
            }
        }
    }
    @media (max-width: 915px) {
        .top {
            width: 100%;
            padding: 0;

            .card {
                min-width: 85%;
            }
        }
    }

    .historyTitle {
        font-size: 20px;
        font-weight: 500;
        margin: 0 20px;
    }

    .gridContain {
        width: calc(100% - 30px);
        padding-left: 30px;

        .listItem {
            display: flex;

            .listEdit {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 10px;
                padding: 5px 10px;
                background-color: #3bb077;
                color: white;
                cursor: pointer;
                font-family: bYekanN, bYekan;
            }

            .span {
                display: flex;
                align-items: center;
                margin: 50px 0;
            }
        }
    }
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
