.referral {
    flex: 5;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    color: #fff;
    padding: 0 20px;

    .mask {
        display: none;
    }
    @media (max-width: 915px) {
        .mask {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            position: absolute;
            z-index: 1001;
            transition: opacity 0.8s, width 0.2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        .mask.close {
            opacity: 0;
            width: 0;
        }
    }

    .topFirst {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;

        .left {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                font-size: 60px;
                color: #fff;
            }

            .titleContain {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .mainTitle {
                    font-size: 28px;
                    font-weight: 500;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;

            .rightContent {
                display: flex;
                align-items: center;
                overflow: hidden;

                .buyBtn {
                    width: 100px;
                    height: 40px;
                    background-color: #13cc68;
                    overflow: hidden;
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 20px;
                    cursor: pointer;
                    border: none;
                }
                .buyBtn:hover {
                    background-color: #0d7c41;
                }
                .buyBtn:active {
                    background-color: #084e29;
                }

                .profileContain {
                    flex: 0.5;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                .infoContain {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 5;
                    color: #fff;
                    padding: 0 10px;
                    overflow: hidden;

                    .name {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                    }
                    .email {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 11px;
                        font-weight: 200;
                        color: rgb(156, 156, 156);
                    }
                }
                .notifContain {
                    display: flex;
                    flex: 0.5;

                    .combine {
                        position: relative;
                        cursor: pointer;

                        .icon {
                            padding: 5px;
                            background-color: #393a3f;
                            border-radius: 10px;
                            margin-right: 10px;
                            font-size: 20px !important;
                            color: #fff;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 3px;
                            right: 15px;
                            border-radius: 50%;
                            background-color: #13cc68;
                        }
                    }
                }
            }
        }

        .leftMobile {
            display: none;
        }
        .middleMobile {
            display: none;
        }
        .rightMobile {
            display: none;
        }
    }
    @media (max-width: 915px) {
        .topFirst {
            padding: 20px 0;

            .left {
                display: none;
            }
            .right {
                display: none;
            }

            .leftMobile {
                flex: 0.2;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 0 10px;

                .menuBtn {
                    cursor: pointer;
                }
            }
            .middleMobile {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
            .rightMobile {
                flex: 0.2;
                display: flex;
                padding: 0 10px;

                .profileContain {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgContain {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        border: solid #fff;

                        .img {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
            }
        }
    }

    .topicContain {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        margin-top: 20px;
        padding-bottom: 250px;
        overflow: hidden;

        .topicFirst {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: rgb(18, 17, 17);
            background: linear-gradient(0deg, rgba(18, 17, 17, 1) 0%, rgba(26, 28, 32, 1) 100%);
            font-size: 25px;
            font-weight: 500;
            text-align: center;
            border-radius: 10px;
            padding: 30px 0 160px 0;
            overflow: hidden;
        }

        .topicSecond {
            display: flex;
            width: 70%;
            height: 150px;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px 20px;
            position: absolute;
            bottom: 0;

            .imgContain {
                flex: 0.35;
                display: flex;
                align-items: flex-end;
                .topicImg {
                    width: 100%;
                    height: 250px;
                }
            }
            .topicInfoContain {
                flex: 1;
                display: flex;
                flex-direction: column;
                color: #000;
                .topicInfo {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .topicLeft {
                        flex: 0.4;
                        padding-right: 5px;
                        font-size: 17px;
                        font-weight: 500;
                    }

                    .topicRight {
                        flex: 1;
                        .copyPart {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            background-color: transparent;
                            border: none;
                            outline: none;

                            span {
                                align-items: center;
                                padding: 15px 10px;
                                border-radius: 10px;
                                color: #0088ea;
                                font-size: 14px;
                                font-weight: 400;
                                display: inline-block;
                                width: 300px;
                                white-space: nowrap;
                                overflow: hidden !important;
                                text-overflow: ellipsis;
                            }
                            @media (max-width: 915px) {
                                span {
                                    display: inline-block;
                                    width: 80px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                }
                            }
                            .copyIcon {
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 915px) {
            .topicFirst {
                font-size: 15px;
            }

            .topicSecond {
                width: calc(90% - 20px);
                .imgContain {
                    flex: 0;
                }
            }
        }
    }

    .commissionStatsContain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: stretch;
        width: calc(100% - 60px);
        padding: 30px 30px;
        background: rgb(77, 78, 89);
        background: linear-gradient(90deg, rgba(77, 78, 89, 0.7) 0%, rgba(43, 46, 51, 0.6) 100%);
        margin-top: 2rem;
        border-radius: 10px;

        .commissionStats {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .topicCommission {
                font-size: 17px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 10px;
            }

            .valueCommission {
                font-size: 25px;
                font-weight: 700;
                color: #13cc68;
                margin-bottom: 10px;
            }

            .btnWithdraw {
                height: 30px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #13cc68;
                cursor: pointer;
                color: #fff;
                border: none;
                padding: 5px 15px;
                font-size: 14px;
                margin-bottom: 10px;

                &:hover {
                    background-color: rgb(14, 145, 75);
                }
                &:disabled {
                    cursor: not-allowed;
                }
                &:active {
                    background-color: rgb(7, 82, 42);
                }
            }
        }
    }

    .commissionHistory {
        width: calc(100% - 60px);
        padding: 10px 30px;
        background-color: #22262e;
        margin: 2rem 0;
        border-radius: 10px;

        .listItem {
            display: flex;

            .listEdit {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 10px;
                padding: 5px 10px;
                background-color: #3bb077;
                color: white;
                cursor: pointer;
                font-family: bYekanN, bYekan;
            }

            .span {
                display: flex;
                align-items: center;
                margin: 50px 0;
            }
        }
    }
    @media (max-width: 915px) {
        .commissionHistory {
            width: calc(100% - 20px);
            padding: 10px;
        }
    }
}
@media (max-width: 915px) {
    .referral {
        padding: 0 10px;
    }
}
